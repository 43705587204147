export default {
	italian: "Italian",
	english: "English",
	japanese: "Japanese",
	french: "French",
	german: "German",
	spanish: "Spanish",
	general: {
		back: "Back",
		go: "Go",
		next: "Next",
		team: "Team",
		round: "Round",
		ranking: "Ranking",
		results: "Results",
		total: "Total",
		remaining: "Remaining",
		answer: "Answer",
		units: "Available",
		shop: "Shop",
		ingredients: "Ingredients",
		rices: "Rice",
		plates: "Plates",
		choosing: "Choosing",
		save: "Save",
		cancel: "Cancel",
		name: "Name",
		buy: "Buy",
		photo: "Photo",
		redo: "Redo",
		password: "Password",
		exit: "Exit",
		canceled: "Cancelled",
		event: "Event",
		day: "Day",
		ingredient: "Ingredient",
		points: "Points",
		position: "Position",
		start: "Start",
		from: "Up to",
		error: "Error",
		chat: "Chat",
		game_master: "STAFF",
		options: "Options",
		team_name: "Team Name",
		option_organizer: "STAFF Options",
		btn_send_answer: "Send answer",
		btn_continue: "Continue",
		correct_answer: "Correct answer",
		incorrect_answer: "Wrong answer",
		challenge_complete: "Challenge completed",
		try_again: "Error, try again",
		save_changed: "Saved changes",
		instructor: "Instructor",
		ranking_block: "The admin has hidden the ranking",
		close: "Close",
		agency: "Agency",
		delete: "Delete",
		updated: "updated",
		created: "created",
		update: "update",
		create: "create",
		filters: "Filters",
		add: "Add",
		edit: "Edit",
		accept: "Accept",
		unsaved_changes: "There are unsaved changes",
		en: "English",
		es: "Spanish",
		yes: "Yes",
		no: "No",
		ipad_title: "Ipad Hunt",
		tapas_title: "Tapas, Paella, Sangria",
		utq_title: "Ultimate Team Quest",
		action_title: "Action Challenge",
		hours: "Hours",
		minutes: "Minutes",
		new: "New",
		mark_all_read: "Mark all as read",
		name_app: "TEAMBUILDING APP",
		hello: "Hello",
		reload_app: "Reload APP",
		change_monitor: "Change monitor",
		must: "Mandatory",
		first: "First",
		incorrect_fields: "There are incorrect fields in the form",
		answer_correct: "Correct answer",
		answer_incorrect: "Incorrect answer",
		pair_a: "Pair A ",
		pair_b: "Pair B ",
		order_colon: "Order: ",
		quiz_multi: "Multiple choice quiz",
		answer_auto: "Self-correcting answer",
		order: "Order",
		click_add: "Click to add",
		click_upload: "Click to upload",
		quiz: "Quiz",
		pairs: "Pairs",
		min_answers: "There must be a minimum of {min} answers",
		max_answers: "The limit of answers is {max}",
		file_large: "File too large, the maximum is 50MB",
		new_item: "New item",
		add_item: "Add an item",
		missing_items: "No items added",
		select_item: "Select an item",
		total_price: "Total {total_price}€",
		app_activity_plus: "+ App Activity",
		app_activity: "App Activity",
		url_admin: "Admin URL",
		url_gm: "GM URL",
		preview: "Preview",
		actions: "Actions",
		name_company: "Teaming Labs",
		challenges: "Challenges",
		all: "All",
		new_contact: "New contact",
		missing_contacts: "No contacts added",
		select_contact: "Select a contact",
		demo_upper: "DEMO",
		add_contact: "Add a contact"
	},
	player_views: {
		event: {},
		home: {
			user_registered: "User registered",
			active_events: "View active events",
			insert_code: "Enter event code // team",
			qr_scan: "Scan QR",
			code_event: "Event code",
			code_team: "Team code",
			event_not_exist: "This event does not exist",
			team_not_exist: "This team does not exist"
		},
		player_view: {}
	},
	public_views: {
		tapas: {
			presentation_tapas: {
				end: "End of presentation"
			},
			ranking_tapas: {
				round_ends: "Round ends",
				team_turn: "Team turn",
				choice_type: "Choice type",
				choice_round: "Choice round",
				total_round: "Total round",
				time_preparation: "Preparation time"
			},
			results_tapas: {}
		},
		utq: {
			ranking_utq: {
				round_ends: "Round ends"
			},
			results_utq: {}
		}
	},
	tapas: {
		tapas_player: {
			change_name: "Change name",
			time_choose: "Time to choose",
			presentation_in: "Presentation in",
			final_presentation: "Final presentation",
			dish_name_PC: "Paella name",
			dish_name_Tapas: "Tapa name",
			dish_name_MS: "Sangria name",
			team_photo: "Team photo",
			dish_photo_PC: "Paella photo",
			dish_photo_Tapas: "Tapa photo",
			dish_photo_MS: "Sangria photo",
			available_money: "Available money",
			choose_ingredient: "Choose ingredient",
			team_choosing: "Team choosing",
			choose_rice: "Choose rice",
			choose_plates: "Choose plate",
			choose_decoration: "Choose decoration/extra",
			change_team_name: "Change team name",
			buy_confirm: "Confirm purchase",
			meal_photo: "Meal photo",
			exit_event: "Exit event",
			answer_correct: "The answer is correct",
			answer_incorrect: "The answer is incorrect",
			name_sent: "Name sent",
			item_purhased: "Item purchased, you can now go pick it up at the store",
			no_unit: "No units available",
			password_incorrect: "Incorrect password",
			name_changed: "Name changed",
			item_purchased: "Purchased utensil"
		}
	},
	modals: {
		upload_photo: {
			change_camera: "Change Camera",
			upload_photo: "Upload Photo",
			photo_success: "Photo sent"
		}
	},
	stores: {
		tapas: {
			main_ingredient: "Compulsory ingredient",
			global_winner: "Global",
			flavour_winner: "Flavour",
			presentation_winner: "Present.",
			defense_winner: "Defense",
			money_winner: "Money",
			photo_tapa: "P. TAPA",
			photo_team: "P. TEAM",
			tapa: "TAPA",
			position: "POSITION",
			points: "POINTS"
		}
	},
	chat: {
		message: "Message",
		message_placeholder: "Type",
		message_btn: "Send message",
		send: "Sent",
		read: "Read"
	},
	ipadHunt: {
		ipadHunt_player: {
			must_challenge: "Compulsory challenge",
			modal_password: "Ask a member of the STAFF for the password.",
			modal_error_download_title: "Content not saved",
			modal_error_download_text: "Download content again",
			modal_download_content_title: "Downloading content",
			modal_download_content_text: "Downloading content",
			modal_error_location_title: "Location not available",
			modal_error_location_text: "Give the app permission to use your location or contact a member of the staff.",
			reload_app: "Refresh app",
			content_correct: "Content downloaded correctly",
			content_incorrect: "There are errors in the content. Please download again.",
			delete_content: "Delete content and download again.",
			answer_send: "Your answer has been submitted. You will receive the corresponding points in a few minutes.",
			match: "Match",
			take_photo: "Take photo",
			take_video: "Take video",
			error_wrong_password: "Wrong password",
			error_no_answer: "Answer not detected",
			error_order: "You have to order the answers",
			test_camera: "Test camera",
			modal_test_title: "TEST",
			modal_test_localization_correct: "Correct Location",
			modal_test_localization_incorrect: "Location not available",
			modal_test_ok: "Test completed correctly"
		},
		game_master_ranking: {
			name: "Name",
			points: "Points",
			challenges_done: "Challenges Completed",
			last_challenge: "Last Challenge"
		}
	},
	actionChallenge: {
		actionChallenge_player: {
			audio_error: "Your browser does not support the audio element.",
			need_material: "En esta prueba necesitas material",
			continue: "CONTINUAR",
			send_response: "ENVIAR RESPUESTA",
			correct_response: "RESPUESTA CORRECTA",
			correct_responses: "RESPUESTAS CORRECTAS",
			incorrect_response: "RESPUESTA INCORRECTA",
			money_won: "Dinero ganado",
			response: "Respuesta",
			btn_match: "Match",
			successes: "Aciertos",
			similarity: "La palabra coincide un {similarity_percentage}% a las palabras correctas",
			category_no_elements: "No hay elementos en esta categoria",
			not_started: "EL EVENTO TODAVIA NO HA EMPEZADO",
			challenge_warning: "Tras comprar un reto, ve a buscar el material a la tienda para completarlo.",
			pick_material: "VE A BUSCAR EL MATERIAL A LA TIENDA PARA EMPEZAR ESTE RETO",
			challenge_send: "LA RESPUESTA HA SIDO ENVIADA Y SERA EVALUADA POR EL GAME MASTER",
			return_material: "DEVUELVE EL MATERIAL A LA TIENDA PARA SELECCIONAR EL SIGUIENTE RETO",
			points_won: "Puntos ganados",
			wrong_answer: "RESPUESTA INCORRECTA",
			game_finished: "LA PARTIDA HA FINALIZADO",
			return_material_finish: "DEVUELVE EL MATERIAL DEL RETO A LA TIENDA",
			no_material: "En estos momentos no hay material suficiente para hacer esta prueba",
			no_money: "No tienes dinero suficiente para comprar esta prueba",
			shop: "Shop",
			obejcts_buy: "Purchased objects",
			quantity: "Quantity",
			modal_buy_text: "Buy {object} for {money}$",
			error_item_buy_no_money: "You don't have enough money",
			error_item_buy_no_stock: "No stock available",
			error_item_buy_can_not_more: "You cannot buy more of these objects",
			ok_item_buy: "Object purchased, you can now collect it",
			construction_name: "Name of the construction",
			construction_photo: "Photo of the construction"
		}
	},
	camera: {
		title_error_modal: "Camera error",
		text_error_modal: "Ask a member of the STAFF for support. Error: ",
		btn_reload_app: "Refresh App",
		btn_load_foto: "Add picture from gallery",
		modal_upload_title: "Upload Photo",
		modal_upload_text: "You can upload a picture from the gallery",
		error_no_upload: "Upload a picture first",
		error_upload_photo: "Photo not uploaded, try again"
	},
	menu: {
		sales: "Sales",
		budgets: "Budgets",
		clients: "Clients",
		bookings: "Bookings",
		booking_list: "Booking list",
		calendar: "Calendar",
		activities: "Activities",
		demos: "Demos",
		content: "Content",
		settings: "Settings",
		settings_erp: "ERP Settings",
		organization: "Organization",
		activities_erp: "Activities",
		extras: "Extras",
		final_page_budget: "Final Budget Page",
		activities_questions: "Activities Questions",
		notifications: "Notifications",
		logout: "Log out"
	},
	budgets: {
		budgets: "Budgets",
		status: "Status",
		search: "Search",
		search_name: "Search by name",
		name: "Name",
		client: "Client",
		people: "People",
		date: "Date",
		open: "Open",
		won: "Won",
		lost: "Lost",
		all: "All",
		new_budget: "New Budget",
		must_name: "Must have a name",
		budget_validity: "Budget validity",
		hour: "Hour",
		show_total: "Show total",
		language: "Language",
		spanish: "Spanish",
		english: "English",
		conditions: "Conditions",
		extras: "Extras",
		budget: "Budget",
		close_budget: "Close Budget",
		download: "Download",
		select_option: "Select an option",
		select_items: "Select the items to be transferred to the reserve",
		comments_close_budget: "Comments on the closure of the budget",
		convert: "Convert",
		close: "Close",
		back: "Back",
		cancel: "Cancel",
		line: "line",
		auto_price: "Automatic Price",
		total: "Total",
		description: "Description",
		price_tag: "Price Tag",
		pax: "Pax",
		price: "Price",
		user: "User",
		no_condition: "No conditions",
		comments: "Comments",
		activity: "Activity",
		location: "Location",
		item: "Item",
		first: "First"
	},
	bookings: {
		bookings: "Bookings",
		name: "Name",
		client: "Client",
		people: "People",
		date: "Date",
		search_name: "Search by name",
		search: "Search",
		new_booking: "New Booking",
		general: "General",
		event: "Event",
		questions: "Questions",
		economics: "Economics",
		ratings: "Ratings",
		language: "Language",
		hour: "Hour",
		client_logo: "Client Logo",
		billing_data: "Billing Data",
		activities_app: "Activities App",
		type_event: "Type of Event",
		place: "Place",
		url_location: "Location URL",
		place_information: "Place Information",
		location: "Location",
		must_name: "Must have a name",
		general_information: "General information",
		extra_information: "Extra information",
		extra_information_internal: "Internal extra information",
		timeline: "Schedule",
		description: "Description",
		add_activity: "Add activity",
		number_teams: "Number of teams",
		staff_people: "Staff members",
		teams: "Teams",
		staff: "Staff",
		add_team: "Add team",
		add_staff: "Add staff member",
		contact_person: "Contact person",
		add_person_contact: "Add contact person",
		add_item: "Add an item",
		add_questions: "Add questions",
		modify_questions: "Modify questions",
		add: "Add",
		all_questions: "All questions",
		activity_questions: "Activity questions",
		costs: "Costs",
		price: "Price",
		activity: "Activity",
		reserve_item: "Item Reservation",
		gm_pay: "GM Pay",
		add_gm: "Add GM",
		game_master: "Game master",
		salary: "Salary",
		total: "Total",
		gm_valoration: "Game master rating",
		client_valoration: "Client rating",
		es: "Spanish",
		en: "English",
		cat: "Catalan",
		email: "Email",
		phone: "Phone",
		extra: "Extra",
		online: "Online",
		in_person: "In person",
		booking_activities: "App Activities",
		name_place: "Place name",
		direction_location: "Site URL address",
		add_cost: "Add cost",
		add_field: "Add field",
		review_gm_enjoy: "Did the participants enjoy themselves?",
		review_gm_size: "How would you rate the size of the team of facilitators?",
		review_gm_previous_info: "How would you rate the information provided before the activity?",
		review_gm_started: "When did the activity start?",
		review_gm_material: "Has any material been damaged or lost?",
		review_gm_comments: "Comments or incidents?",
		review_expectations: "To what extent did our services meet your expectations?",
		review_scale: "On a scale from 1 to 10, how likely are you to recommend our services to someone in your circle?",
		review_gamemasters: "How would you rate the attention of the facilitators during the activity?",
		review_content: "How would you rate the content of the activity?",
		review_service_prices: "How would you rate the price of the services?",
		review_previous_organization: "How would you rate the organization prior to the activity?",
		review_hire_again: "What are the chances that you would hire our service again?",
		review_comments: "Do you have any comments or suggestions for us?",
		expected_not: "They did not meet my expectations",
		expected_more: "I expected more",
		expected_fulfilled: "They fulfilled what I expected",
		expected_exceeded: "They exceeded my expectations",
		very_unsatisfactory: "Very unsatisfactory",
		unsatisfactory: "Unsatisfactory",
		neutral: "Neutral",
		satisfactory: "Satisfactory",
		very_satisfactory: "Very satisfactory",
		no_complaints: "No, there have been many complaints",
		standing_group: "Not much, standing group",
		generally_yes: "Generally yes",
		highly_motivated: "Yes, very motivated group",
		staff_shortage_critic: "There was critically lacking staff",
		staff_shortage: "There was not enough staff to be comfortable",
		correct: "Correct",
		staff_excess: "Excess staff",
		missing_information: "Missing important information",
		missing_details: "Missing details",
		enough: "Enough",
		surplus: "More than enough",
		early_expected: "Earlier than expected",
		on_point: "On time",
		ten_late: "0-10 min late",
		thirty_late: "10-30 min late",
		more_late: "+30 min late",
		user: "User",
		timetable: "Schedule",
		fundae: "FUNDAE",
		bill_sent: "Invoice sent",
		client_information: "Client information",
		app: "App",
		event_details: "Event details",
		staff_persons: "Staff",
		incomes: "Income",
		placeholder_name: "Name",
		alert_name: "Must have a name",
		valoration_available: "RATING (available at the end of the event)",
		new_contact: "New contact",
		very_likely: "Very likely",
		likely: "Likely",
		unlikely: "Unlikely",
		very_unlikely: "Very unlikely",
		media: "Media"
	},
	activities: {
		activities: "Activities",
		activity: "Activity",
		game: "Game",
		day: "Day",
		enter: "Enter",
		create_activity: "Create Activity",
		sure: "Are you sure?",
		delete: "Delete",
		date_hour: "Date and time",
		name_activity: "Name of the activity",
		type_activity: "Type of activity",
		language: "Language",
		comments: "Comments",
		logo: "Logo",
		demo: "Demo",
		alert_name_activity: "Give a name to the activity.",
		select_language: "Select a language",
		select_challenge: "Select a challenge",
		select_date: "Select a date",
		select_activity: "Select a type of activity",
		select_name: "Give a name to the activity",
		select_primary_color: "Select a primary color.",
		select_secondary_color: "Select a secondary color.",
		save_error: "Could not {message_request_negative} the event",
		save_ok: "Event {message_request} successfully",
		delete_error: "Could not delete the event",
		delete_ok: "The event has been deleted",
		tl_activity: "Teaming Labs Activity",
		click_add: "Click to Add",
		dashboard: "Dashboard"
	},
	demos: {
		demos: "Demos",
		create_demo: "Create Demo"
	},
	content: {
		content: "Content",
		list_content: "Content List",
		no_template: "No Template",
		all: "All",
		ipad: {
			ipad_challenges: "iPad Challenges",
			must: "Required",
			optional: "Optional",
			first: "First",
			content_list: "Content List",
			name: "Name",
			setting: "Setting",
			type: "Type",
			quiz: "Quiz",
			open_text: "Open Text",
			photo: "Photo",
			video: "Video",
			alert: "Alert",
			team_photo: "Team photo",
			order: "Order",
			match: "Match",
			type_challenge: "Type of challenge",
			id_name: "Identifier name",
			password: "Password",
			time: "Time",
			max_points: "Maximum points",
			template: "Template",
			description: "Description",
			file_1: "File 1",
			file_2: "File 2",
			file_3: "File 3",
			placeholder_type: "Select a type",
			alert_type: "Select a test type",
			placeholder_name_id: "Test identifier name...",
			alert_name_id: "Give a name to the test.",
			placeholder_name: "Test name...",
			placeholder_password: "Password...",
			placeholder_description: "Test description...",
			expected_answer: "Expected Answer",
			placeholder_expected_answer: "Expected answer",
			challenge: "Challenge",
			placeholder_team: "Team.."
		},
		tapas: {
			tapas_challenges: "Tapas Challenges",
			ingredients: "Ingredients",
			item_shop: "Utensil shop",
			name: "Name",
			type: "Type",
			quiz: "Quiz",
			challenge: "Challenge",
			type_challenge: "Test Type",
			id_name: "Identifying Name",
			money: "Money",
			template: "Template",
			image: "Image",
			description: "Description",
			placeholder_type: "Select a type",
			alert_type: "Select a test type",
			placeholder_name_id: "Identifier name of the test...",
			alert_name_id: "Give an identifying name to the test.",
			placeholder_name: "Name of the test...",
			placeholder_description: "Test description...",
			setting: "Setting",
			main_ingredient: "Main ingredient",
			secondary_ingredient: "Secondary ingredient",
			decoration: "Decoration",
			base: "Base",
			quantity: "Quantity",
			quantity_placeholder: "Quantity, e.g., 1 package 200g..."
		},
		utq: {
			challenges_utq: "UTQ Challenges",
			name: "Name",
			placeholder_name: "Test name...",
			id_name: "Identifying Name",
			material: "Material",
			placeholder_material: "Material...",
			description: "Description",
			placeholder_description: "Test description...",
			description_score: "Score description",
			placeholder_description_score: "Description of the test score...",
			image: "Image",
			quiz: "Quiz",
			challenge: "Challenge",
			placeholder_name_id: "Identifier name of the test...",
			alert_name_id: "Provide an identifying name for the test."
		},
		action: {
			quiz: "Quiz",
			category_quiz: "Quiz Category",
			challenge: "Challenge",
			challenges: "Challenges",
			name: "Name",
			category: "Category",
			type: "Type",
			setting: "Setting",
			init_challenge: "Initial Challenge",
			init_challenges: "Initial Challenges",
			order: "Ordering",
			match: "Matching",
			quiz_multi: "Multiple-Choice Quiz",
			text_auto: "Self-Correcting Response",
			type_challenge: "Type of Test",
			placeholder_type: "Select a type",
			alert_type: "Select a type of test",
			id_name: "Identifying Name",
			placeholder_name_id: "Identifier name of the test...",
			alert_name_id: "Give an identifying name to the test.",
			placeholder_name: "Name of the test...",
			description: "Description",
			placeholder_description: "Test description...",
			image_quiz: "Quiz image",
			max_money: "Maximum money",
			need_material: "Needs material",
			material: "Material",
			placeholder_material: "Necessary material...",
			expected_answer: "Expected answer",
			placeholder_expected_answer: "Expected answer",
			file_1: "File 1",
			file_2: "File 2",
			action_challenge_quiz: "Action Challenge Quiz",
			photo: "Photo",
			video: "Video",
			team_photo: "Team photo",
			template: "Template",
			max_points: "Max points",
			cost: "Cost",
			video_duration: "Video duration",
			short_description: "Short description",
			placeholder_short_description: "Short description...",
			help_gm: "Help to score",
			help_file: "Solution Image",
			internal_description: "Internal description",
			placeholder_internal_description: "Internal test description...",
			text_data: "Text response"
		},
		actionChallengeShopItem: {
			one_per_team: "One per team",
			two_per_team: "Two per team",
			one_per_two_teams: "One for every two teams",
			one_per_three_teams: "One for three teams",
			identifying_name: "Identifying Name",
			identifying_name_placeholder: "Identifier name of the questionnaire...",
			identifying_name_alert: "Provide an identifying name for the questionnaire.",
			image: "Image",
			cost: "Cost",
			max_per_team: "Maximums per team",
			template: "Template",
			default_material: "Default material",
			material_description: "Material description",
			name: "Name",
			name_placeholder: "Name of the test...",
			description: "Description",
			description_placeholder: "Description of the test..."
		},
		downloading_content: "Downloading content"
	},
	settings: {
		settings: "Settings",
		settings_list: "Settings list",
		ipad: {
			name: "Name",
			id_name: "Identifying name",
			placeholder_name_id: "Identifier name for the test...",
			alert_name_id: "Please give an identifying name to the test.",
			main_color: "Main color",
			secondary_color: "Secondary color",
			stopwatch_color: "Stopwatch color",
			background_image: "Background image",
			points_icon: "Points icon",
			challenges: "Challenges",
			challenge: "Challenge"
		},
		tapas: {
			name: "Name",
			id_name: "Identifier name",
			placeholder_name_id: "Identifier name for the test...",
			alert_name_id: "Put an identifier name for the test.",
			secondary: "Secondary",
			decoration: "Decoration",
			processing_time: "Processing time",
			ingredients: "Ingredients",
			item_shop: "Utensil Shop",
			setting: "Settings"
		},
		action: {
			name: "Name",
			id_name: "Identifier Name",
			placeholder_name_id: "Identifier name of the test...",
			alert_name_id: "Please provide an identifier name for the test.",
			challenges: "Challenges",
			categories: "Categories",
			challenge: "Challenge",
			game_mode: "Game Mode"
		}
	},
	erp_settings: {
		organization: "Organization",
		name: "Name",
		placeholder_name: "Name",
		alert_name: "Must have a name",
		logo: "Logo",
		billing: "Billing",
		placeholder_billing: "Billing data...",
		list_activities: "List of Activities",
		new_activity: "New Activity",
		search: "Search",
		placeholder_search: "Search by name",
		in_person: "In-person",
		online: "Online",
		modality: "Modality",
		alert_modality: "Select a modality",
		general_media: "General File",
		pricing_list: "Price List",
		activity_name: "Name",
		placeholder_activity_name: "Activity name (Client)",
		duration: "Duration",
		placeholder_duration: "Activity Duration",
		description: "Description",
		placeholder_description: "Activity Description",
		requirements: "Requirements",
		placeholder_requirements: "Activity Requirements",
		includes: "Includes",
		placeholder_includes: "What the activity includes",
		dynamic: "Dynamic",
		placeholder_dynamic: "Activity Dynamics",
		budget_description: "Budget Description",
		placeholder_budget_description: "Budget Description",
		media: "File only in this language",
		extras: "Extras",
		price: "Price",
		activity: "Activity",
		type_price: "Price Type",
		alert_type_price: "Select a price type",
		new_extra: "New Extra",
		preview_pdf: "Preview PDF",
		extra_deleted: "The extra has been deleted",
		extra_not_deleted: "The extra has not been deleted",
		placeholder_name_extra: "Extra Name",
		placeholder_description_extra: "Extra Description",
		extra: "Extra",
		total: "Total",
		by_person: "Per Person",
		final_page_budget: "Final Page Budget",
		new_final_page_budget: "New Final Page Budget",
		final_page_budget_deleted: "The final page budget has been deleted",
		final_page_budget_not_deleted: "The final page budget could not be deleted",
		placeholder_description_activity: "Description of the activity",
		question: "Question",
		placeholder_question: "Question",
		all_activities: "All activities",
		no_activity: "No activity",
		placeholder_activity: "Filter by activity",
		questions_activities: "Questions about activities",
		link_app: "Link App",
		code: "Code",
		placeholder_code: "Activity code"
	},
	previews: {
		menu: "Menu"
	},
	login: {
		description: "Teaming Labs application for conducting Team Building events",
		email: "Email",
		placeholder_email: "Example@email.com",
		password: "Password",
		placeholder_password: "At least 8 characters",
		sign_in: "Sign In",
		forgor_password: "Forgot password"
	},
	formGenerator: {
		pricing: {
			new_price: "+ New price line",
			min_persons: "Minimum persons",
			max_persons: "Maximum persons",
			total_price: "Total price",
			person_price: "Price per person",
			price: "Price"
		}
	},
	dialogs: {
		sure: "Are you sure?",
		erase: "Delete",
		erase_record: "Delete record",
		delete_team: "Delete team",
		delete: "Delete",
		delete_colon: "Delete: ",
		cancel: "Cancel",
		name: "Name",
		add: "Add",
		placeholder_team: "Team..",
		edit_team: "Edit team",
		save: "Save",
		demo_limit: "Demo activities only allow 2 teams",
		link_staff: "Staff link",
		link_admin: "Admin link",
		link_results: "Results link",
		go_results: "Go to results",
		add_team: "Add team",
		material_recalculate: "When creating the team, the material will be recalculated",
		warning: "Warning",
		add_team_money: "Add team money",
		link_teams: "Teams link",
		challenge_delete: "The test will be deleted: ",
		create: "Create",
		challenge: "Challenge",
		placeholder_challenge: "Select a challenge",
		warning_change_template: "Changing the template will delete all tests",
		team: "Team",
		points_negative: "Points (can be negative)",
		money_negative: "Money (can be negative)",
		minutes: "Minutes",
		seconds: "Seconds",
		substract: "Subtract",
		expected_answer: "Expected answer: ",
		challenge_score: "Score challenge",
		activate: "Activate",
		material_recalculate_delete: "Deleting the team will recalculate the material",
		add_route: "Add route",
		confirm: "Confirm",
		accept: "Accept",
		activity: "Activity",
		language: "Language",
		placeholder_language: "Select a language",
		logo: "Logo",
		sure_delete_acitivty: "Are you sure you want to delete the activity?",
		add_favorite: "Add to favorites",
		delete_favorite: "Remove from favorites",
		date: "Date",
		unsaved_changes_content: "Are you sure?",
		unsaved_changes_title: "Unsaved changes will be lost",
		stay: "Stay",
		exit: "Exit",
		client_validate: "Customer data needs to be validated"
	},
	gm: {
		modals: {
			add_monitor: "Add monitor",
			save: "Save",
			name: "Name",
			placeholder_name: "Monitor Name",
			saved_monitor: "Monitor saved",
			continue: "Continue",
			change: "Change",
			exit: "Exit",
			cancel: "Cancel",
			exit_event: "Exit the event",
			password: "Password"
		},
		dashboards: {
			configuration: "Configuration",
			link_ranking: "Link Ranking",
			link_results: "Link Results",
			link_monitors: "Link Monitors",
			link_admin: "Link Admin",
			link_presentation: "Link Presentation",
			link_teams: "Link Teams",
			list_ingredients: "List of ingredients",
			link_favorite_images: "Link Favorite Images",
			total: "Total",
			remaining: "Remaining",
			round_ends: "Round ends",
			round: "Round",
			play: "Play",
			resume: "Resume",
			pause: "Pause",
			pass_round: "Skip Round",
			time_ranking: "View time in ranking",
			results: "Results",
			add_team: "Add Team",
			add_teams: "Add Teams",
			order_points: "Sort by points",
			add_challenges_first: "Add challenges first",
			general: "General",
			material: "Material",
			event_configuration: "Configure the event first",
			back: "Back",
			save: "Save",
			challenges: "Challenges",
			score_categories: "Score Categories",
			defense: "DEFENSE",
			presentation: "PRESENTATION",
			flavor: "FLAVOR",
			money: "MONEY",
			round_time: "Time per round (seconds)",
			tapas_time: "Tapas time (seconds)",
			type: "Type",
			placeholder_type: "Select a type",
			ingredient: "Ingredients",
			shop: "Shop",
			select_challenge: "Select a challenge",
			select_activity: "Select an activity",
			select_time_round: "Set a time per round",
			select_time_tapas: "Set a time to complete the tapas",
			select_categories: "Please select the categories",
			team_turn: "Team turn",
			type_election: "Type of election",
			round_election: "Round of election",
			round_total: "Total rounds",
			time_elaboration: "Preparation time",
			name_team: "Give the team a name",
			number_teams: "Number of teams",
			total_ingredients: "Total ingredients",
			extra_ingredients: "Extra ingredients",
			total_decoration: "Total decoration",
			extra_decoration: "Extra decoration",
			missing_ingredients: "Missing ingredients",
			list_download: "Download List",
			ingredients: "Ingredients",
			saved: "Saved",
			start_game: "Start Game",
			gm: "Game Master",
			readed: "Read",
			sent: "Sent",
			message_team: "Write a message to the team",
			message_all_teams: "The messages you write will be sent to all teams",
			your_message: "Your message",
			upload_image: "Upload image",
			add_emoji: "Add emoji",
			send_message: "Send message",
			all: "All",
			challenge: "Challenge",
			id_name: "Identifying Name",
			latitude: "Latitude",
			longitude: "Longitude",
			activations_radius: "Activation Radius",
			categories_points: "Score Categories",
			must_upper: "MANDATORY",
			first_upper: "FIRST",
			type_activation: "Activation Type",
			qr_upper: "QR",
			location_upper: "LOCATION",
			first: "First",
			must: "Mandatory",
			add_challenges: "Add Challenges",
			select_latitude: "Select a latitude",
			select_longitude: "Select a longitude",
			placeholder_id_name: "Enter an identifying name",
			placeholder_template: "Select a template",
			put_duration: "Enter a duration",
			initial_position: "Initial position",
			not_template: "No template",
			template: "Template",
			placeholder_search_address: "Search for an address...",
			duration_seconds: "Duration (seconds)",
			duration_hours: "Duration Hours",
			duration_minutes: "Duration Minutes",
			categories: "Categories",
			time_action: "Set a time to complete the challenges",
			routes: "Routes",
			team_no_route: "There are teams without a route",
			changes_not_saved: "Changes not saved",
			route: "Route",
			order: "Order: ",
			name_route: "Name",
			placeholder_name_route: "Route name",
			event: "Event",
			select_setting: "Select a setting",
			initial_challenges: "Initial Challenges",
			time_higher_zero: "The time must be greater than 0",
			time_required: "The time per round is required",
			time_integer: "The time must be an integer"
		},
		utq: {
			change_monitor: "Change Monitor",
			round: "Round",
			ends: "Ends",
			start: "Start",
			team_upper: "TEAM",
			position_upper: "POSITION",
			points_upper: "Points",
			team: "Team",
			placeholder_team: "Select a team",
			score: "Score",
			rate: "Rate",
			instructions_material: "Instructions and material",
			name_event: "Name the event",
			select_team: "Select a team",
			operation: "Operation",
			material: "Material"
		},
		tapas: {
			round_ends: "Round ends",
			score_missing: "There are remaining tests to score for the teams",
			start_challenges: "Start Challenges",
			start_selection: "Start Ingredient Selection",
			start_elaboration: "Start Preparation Time",
			pass_round: "Skip Round",
			selecting_ingredients: "Selecting ingredients",
			pass_selection: "Skip selection",
			team_turn: "Team's turn",
			type_selection: "Type of selection",
			round_selection: "Selection round",
			round_total: "Total round",
			votes: "Votes",
			type_choice: "Type of choice",
			top_1: "Top 1",
			top_2: "Top 2",
			top_3: "Top 3",
			round: "Round",
			time_eleboration: "Elaboration time",
			start: "Start",
			challenges: "Challenges",
			placeholder_challenge: "Select a challenge and press start",
			orders: "Orders",
			change_money: "Change money",
			shop: "Shop",
			challenges_results: "Challenge results",
			ingredient_selection: "Ingredient selection",
			winners: "Winners",
			placeholder_select_team: "Select a team",
			category: "Category",
			rate: "Rate",
			defense: "Defense",
			show: "Presentation",
			flavour: "Flavour"
		},
		ipad: {
			time_game: "Game time",
			start_game: "Start Game",
			add_delete_time: "Add/Delete Time",
			hide_ranking: "Hide Ranking",
			show_ranking: "Show Ranking",
			lock_challenges: "Lock Challenges",
			unlock_challenges: "Unlock Challenges",
			challenges: "Challenges",
			clean_filters: "Clean Filters",
			add_delete_points: "Add/Delete Points",
			activate_challenge: "Activate Challenge",
			no_route: "No Route",
			instructor: "Instructor",
			player: "Player",
			team: "Team",
			points: "Points",
			challenges_done: "Completed Challenges",
			last_challenge: "Last Challenge",
			hour: "Hour",
			must: "Mandatory",
			optionals: "Optional"
		},
		action: {
			start: "Start",
			next: "Next",
			time_game: "Game Time",
			start_game: "Start Game",
			add_delete_time: "Add/Remove Time",
			ranking: "Ranking",
			finish_game: "Finish Game",
			reactivate_game: "Reactivate Game",
			challenges: "Challenges",
			clean_filters: "Clear Filters",
			team_filter: "Team Filter",
			challenge_filter: "Challenge Filter",
			add_delete_points: "Add/Delete Points",
			add_delete_money: "Add/Delete Money",
			activate_quiz: "Activate Quiz",
			ranking_normal: "Normal",
			ranking_coop: "Cooperative",
			ranking_both: "Both",
			ranking_hidden: "Hidden",
			activate_challenge: "Activate Challenge",
			add_favorite: "Add to Favorites",
			delete_favorite: "Remove from Favorites",
			quizzes: "Quizzes",
			challenge_not_start: "No challenge has started yet",
			challenges_pick: "Challenges to pick up",
			challenges_return: "Challenges to return",
			challenges_material: "Challenge material",
			update_stock: "Modify stock",
			stock_upper: "STOCK: ",
			stock: "Stock",
			team_upper: "TEAM",
			challenge_upper: "CHALLENGE",
			returned_upper: "RETURNED",
			delivered_upper: "DELIVERED",
			team: "Team",
			points: "Points",
			money: "Money",
			quizzes_done: "Completed quizzes",
			challenges_done: "Completed challenges",
			last_challenge: "Last challenge",
			material: "Material",
			save: "Save"
		}
	},
	messages: {
		round_pass: "Round skipped",
		canceled: "Canceled",
		deleted: "Deleted",
		updated: "Updated",
		added: "Added",
		score_ok: "Score sent successfully",
		score_not_ok: "Could not send the score",
		score_add: "Add a score",
		select_team: "Select a team",
		team_error: "Could not add the team",
		team_correctly: "Team {msg} added correctly",
		incorrect_fields: "There are incorrect fields in the form",
		tapas_succesfull_updated: "Tapas War activity updated successfully",
		tapas_not_succesfull_updated: "Could not update the Tapas War activity",
		tapas_succesfull_added: "Tapas War activity added successfully",
		tapas_not_succesfull_added: "Could not add the Tapas War activity",
		select_passed: "Selection skipped",
		link_copied: "Link copied",
		votes_deleted: "Votes deleted successfully",
		votes_not_deleted: "Could not delete the votes",
		money_updated: "Money updated",
		teams_added: "Teams added successfully",
		game_started: "Game Started",
		deleted_ok: "Deleted successfully",
		deleted_not_ok: "Could not delete",
		updated_not_ok: "Could not update",
		select_challenge: "Select a challenge",
		challenges: "Challenges",
		template: "Template",
		warning_template: "If you do not choose a template, you will have to create all the challenges manually.",
		ipad_hunt_not_succesfull_added: "Could not add the Ipad Hunt activity.",
		challenge_activated: "Challenge Activated",
		points_updated: "Points Updated",
		time_updated: "Time Updated",
		correctly_scored: "Scored correctly",
		incorrectly_scored: "Could not score correctly",
		team: "Team",
		action_updated_ok: "Action Challenge activity updated successfully",
		action_updated_not_ok: "Could not update the Action Challenge activity",
		action_added_ok: "Action Challenge activity added successfully",
		action_added_not_ok: "Could not add the Action Challenge activity",
		route_created: "Route Created",
		route_deleted: "Route Deleted",
		route_saved: "Route Saved",
		saved: "Saved",
		select_category: "Select a category",
		must_select: "You must select top1 and top 2",
		vote_sent: "Vote sent",
		vote_error: "Error voting",
		correct_score: "Scored Correctly",
		incorrect_score: "Could not score correctly",
		game_reactivated: "Game Reactivated",
		game_finished: "Game Finished",
		write_quesiton: "Write a question",
		event_deleted: "Event deleted",
		select_activity: "You must select the activity",
		event_created: "Event created",
		event_updated: "Event edited",
		event_not_created: "Event could not be created",
		write_question: "Write a question",
		utq_updated: "Ultimate Team Quest activity updated successfully",
		utq_not_updated: "Could not update the Ultimate Team Quest activity",
		url_copied: "URL copied",
		url_error: "Error copying the URL",
		error_deleting_event: "Could not delete the event",
		reopened: "Reopened",
		finished: "Finished",
		cancelled: "Cancelled"
	},
	erp: {
		booking: {
			calendar: {
				calendar: "Calendar",
				production_details: "Production details",
				pax: "Pax",
				language: "Language",
				fundae: "FUNDAE",
				staff_number: "Number of monitors",
				teams_number: "Number of teams",
				bookings: "Bookings",
				budgets: "Budgets"
			},
			reservation: "Reservation",
			client_link: "Client link",
			changes_pending: "Changes pending validation"
		},
		user_settings: {
			title: "User Settings",
			change_password: "Change Password",
			new_password: "New Password",
			enter_new_password: "Enter the new password",
			confirm_password: "Confirm Password",
			confirm_new_password: "Confirm the new password",
			change_password_button: "Change Password",
			change_language: "Change Language",
			language: "Language",
			select_language: "Select a language",
			change_language_button: "Change Language",
			password_required: "Please enter the new password",
			password_min_length: "The password must be at least 8 characters",
			confirm_password_required: "Please confirm the new password",
			passwords_not_match: "The passwords do not match",
			password_changed_success: "Password changed successfully.",
			password_change_error: "Error changing the password. Please try again.",
			form_errors: "Please correct the errors in the form.",
			spanish: "Spanish",
			english: "English",
			language_required: "Please select a language",
			language_updated_success: "Language updated successfully.",
			language_update_error: "Error updating the language. Please try again.",
			select_valid_language: "Please select a valid language."
		},
		configuration: {
			organization: {
				link_app: "Application link",
				organization_url: "Organization URL",
				copy: "Copy",
				users: "Users",
				create_new_user: "Create New User",
				edit_user: "Edit User",
				name: "Name",
				enter_name: "Enter the name",
				role: "Role",
				cancel: "Cancel",
				save: "Save",
				email: "Email",
				enter_email: "Enter the email",
				password: "Password",
				enter_password: "Enter the password",
				confirm_password: "Confirm Password",
				confirm_password_placeholder: "Confirm the password",
				create: "Create",
				confirm_deletion: "Confirm deletion",
				delete_user_confirmation: "Are you sure you want to delete user {name}?",
				delete: "Delete",
				organization_qr_code: "Organization QR Code",
				administrator: "Administrator",
				facilitator: "Facilitator",
				edit: "Edit",
				actions: "Actions",
				url_copied: "URL copied to clipboard",
				user_updated: "User updated successfully",
				error_updating_user: "Error updating user",
				user_created: "User created successfully",
				error_creating_user: "Error creating user",
				user_deleted: "User deleted successfully",
				error_deleting_user: "Error deleting user",
				select_role: "Select a role"
			}
		}
	},
	events: {
		active_events: "Active events",
		event: "Event",
		game: "Game",
		day: "Day",
		actions: "Actions",
		view_event: "View event",
		enter: "Enter",
		finish: "Finish",
		reopen: "Reopen",
		delete_event: "Delete event",
		are_you_sure: "Are you sure?",
		accept: "Accept",
		cancel: "Cancel",
		no_content: "No content",
		edit: "Edit",
		download: "Download",
		delete: "Delete"
	},
	bookingmedia: {
		uploadImages: "Upload images",
		downloadImages: "Download images",
		imageUploaded: "Image uploaded",
		imageRemoved: "Image removed"
	}
}
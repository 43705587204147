import { useNotificationStore } from "@/stores/apps/notification"
import { useAuthStore } from "@/stores/auth"
import { type RouteMetaAuth } from "@/types/auth.d"
import { type RouteLocationNormalized } from "vue-router"
import { NotificationWs } from "@/utils/ws/notification_ws"
import { useStoreI18n } from "@/composables/useStoreI18n"
import i18n from "@/utils/i18n";
import { useLocalesStore } from "@/stores/i18n"
import { useNavigation } from "@/composables/useNavigation";

const { goToPage } = useNavigation();
const { t, locale } = i18n.global

export async function authCheck(route: RouteLocationNormalized) {
	const meta: RouteMetaAuth = route.meta
	const { checkAuth, authRedirect, auth, roles } = meta
	

	const authStore = useAuthStore()

	if (route?.redirectedFrom?.name === "Logout") {
		authStore.setLogout()
	}

	if (auth === true) {
		if (!authStore.isLogged) {
			window.location.href = "/login" + window.location.search
			return false
		}
		const storeNotification = useNotificationStore()
		
		// MANAGER FIRST TIME AUTH ( REFRESH PAGE , FIRST OPEN )
		if( Object.keys(authStore.full_user).length == 0)
		{
			// FIRST TIME USER ENTER THE PAGE
			await Promise.all([
				authStore.getUser(),
				storeNotification.fetchNotifications()
			]);
			NotificationWs()
			const localeStore = useLocalesStore();
			localStorage.setItem("language", authStore.full_user.language)
			localeStore.setLocale(authStore.full_user.language)
			// FIRST TIME LOGIN
			if( authStore.first_time_login )
			{
				console.log(authStore.full_user.rol);
				
				window.location.href = authStore.full_user.rol == 1 ? "/events_list" : "/calendar";
			}
		}
		


		if (!authStore.isLogged ) {
			window.location.href = "/login" + window.location.search
		}
		console.log(roles);
		
		// if (roles && !authStore.isRoleGranted(roles)) {
		// 	window.location.href = "/login" + window.location.search
		// 	return false
		// }
	}
	

	if (checkAuth === true) {
		if (authStore.isLogged) {
			if (roles) {
				if (authStore.isRoleGranted(roles)) {
					return authRedirect || ( authStore.full_user.rol == 1 ? "/events_list" : "/calendar" )
				} else {
					return route.path
				}
			}
			return authRedirect || ( authStore.full_user.rol == 1 ? "/events_list" : "/calendar" )
		}
	}
}

// composables/useNavigation.ts
import { useRouter } from 'vue-router';

export function useNavigation() {
  const router = useRouter();

  const goToPage = (routeName: string) => {
    router.push( routeName );
  };

  return {
    goToPage,
  };
}
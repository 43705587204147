import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import type { List } from "lodash";
import { defineStore, acceptHMRUpdate } from "pinia"
import { useTapasStore } from "./tapas";
import type { TapasChallenge } from "./interfaces/tapas_interfaces";
import { translate, translateAll } from "@/utils/translate";
import { initDB , checkImageDb} from "@/utils/indexeddb";
type Challenge = {
	id: number, 
	name: string, 
	description: string, 
	image: string, 
	material: string, 
	scoring_description: string
}


export const useContentStore = defineStore("content", {
	state: () => ({
		fileToUpload:{}, // USED IN FORMGENERATOR TO SAVE THE IMAGE THAT HAVE TO BE UPLOADED OR DELETED
		showAlertUnSaved:false, // USED IN FORMGENERATOR TO SHOW UNSUED CHANGED
		loadingBtnForm:false,
		activities: [],
        challenges: [],
		content: {},
		jsonTableFK:{},
		totalContentToDownload:0,
		currentContentDownloaded:0,
	}),
	actions: {
		async setDefault()
		{
			this.fileToUpload = {} // USED IN FORMGENERATOR TO SAVE THE IMAGE THAT HAVE TO BE UPLOADED OR DELETED
			this.showAlertUnSaved = false // USED IN FORMGENERATOR TO SHOW UNSUED CHANGED
			this.loadingBtnForm = false
			this.activities = []
			this.challenges = []
			this.content = {}
			this.jsonTableFK = {}
			this.totalContentToDownload = 0
			this.currentContentDownloaded = 0
		},
		async getActivities ()
		{
			await axiosToken("get", "events/activity/", {})
			.then((response: AxiosResponse) => {
				this.activities = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async getContentByEvent(event_api_key)
		{
			await simpleAxios("get", "content/get_content_event/" + event_api_key + "/" , {})
			.then(async (response: AxiosResponse) => {
				this.totalContentToDownload = 0;
				this.currentContentDownloaded = 0;
				for (const content in response.data) {
					this.totalContentToDownload += response.data[content].length
				}
				for (const content in response.data) {
					const data = response.data[content]
					this.content[content] = data
					for (let index = 0; index < data.length; index++) {
						this.currentContentDownloaded ++;
						await checkImageDb(content, data[index])
					}
				};
			});
		},
		async getAllMultipleContent(names:Array<string>)
		{
			console.log(names);
			
			const promises = names.map(name => this.getAllContent(name));
			await Promise.all(promises);
		},
		async getAllContent(name:string)
		{
			await axiosToken("get", "content/gateway/" + name + "/", {})
			.then(async (response: AxiosResponse) => {
				const data = response.data.sort((a, b) => b.id - a.id);
				this.content[name] = data
				// DONWLOAD AND SAVE THE DATA
				const fetchData = data;
				console.log(fetchData);
				

				// NO NEED TO DOWNLOAD IN TH EMAIN APP 
				// for (let index = 0; index < fetchData.length; index++) {
				// 	checkImageDb(name, fetchData[index])
				// }
				// check if image exist and is in database
				
				
				
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async getContentByIds(name:string, id:Array)
		{
			await axiosToken("get", "content/gateway/" + name + "/", {id})
			.then(async (response: AxiosResponse) => {
				this.content[name] = response.data
				// DONWLOAD AND SAVE THE DATA
				const fetchData = response.data;
				console.log(fetchData);
				
				for (let index = 0; index < fetchData.length; index++) {
					checkImageDb(name, fetchData[index])
				}
				// check if image exist and is in database
				
				
				
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async getSimpleContent(name:string, id:number)
		{
			await simpleAxios("get", "content/gateway/" + name + "/" + id , {})
			.then(async (response: AxiosResponse) => {
				if( this.content.hasOwnProperty(name))
				{
					this.content[name].push(response.data)
				}
				else
				{
					this.content[name] = [response.data]

				}
				// DONWLOAD AND SAVE THE DATA
				const fetchData = response.data;
				console.log(fetchData);
				
				checkImageDb(name, fetchData)
				// check if image exist and is in database
				
				
				
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async deleteContentById(name:string, id:number)
		{
			return await axiosToken("delete", "content/gateway/" + name + "/" + id , {})
		},
		async updateContentById(name:string, id:number, data:object)
		{
			return await axiosToken("put", "content/gateway/" + name + "/" + id , data)
		},
		async newContentById(name:string, data:object)
		{
			return await axiosToken("post", "content/gateway/" + name + "/"  , data)
		},
	},
	persist: {
		// paths: ["events"]
	},
	getters:{

		// challenges
		
		// ingridients
		getContentIngridient: state => (id:number): any | undefined  =>{
			if  (!( 'ingredient' in state.content))
			{
				return {};
			}
			else
			{
				const ingredient = translate(state.content.ingredient.find(e => e.id == id), ["name", "description"])
				return ingredient;
			}
		},
		getContentIngridients: state => () => {
			if  (!( 'ingredient' in state.content))
			{
				return {};
			}
			else
			{
				const ingredients = translateAll(state.content.ingredient, ["name", "description"])
				return ingredients;
			}
		},
		getContentCards: state => (id:number): any | undefined  =>{
			if  (!( 'card' in state.content))
			{
				return {};
			}
			else
			{
				return state.content.card.find(e => e.id == id);
			}
		},
		getItemShop: state => (id:number): any | undefined  =>{
			if  (!( 'item_shop' in state.content))
			{
				return {};
			}
			else
			{
				const item_shop = translate(state.content.item_shop.find(e => e.id == id), ["name", "description"])
				return item_shop;
			}
		}, 
		getItemsShop: state => () => {
			if  (!( 'item_shop' in state.content))
			{
				return {};
			}
			else
			{
				const items_shop = translateAll(state.content.item_shop, ["name", "description"])
				return items_shop;
			}
		},
		getContentByTypeById: state => (type:String, id:number): any | undefined  =>{
			return state.content[type].find(e=> e.id == id)
		},
		getContentActionChallenge: state => (id:number): any | undefined  =>{
			if  (!( 'action_challenge_challenge' in state.content))
			{
				return {};
			}
			else
			{
				const challenge = translate(state.content.action_challenge_challenge.find(e => e.id == id), ["name", "description", "short_description"])
				return challenge;
			}
		},
		getChallengesOptions: state => () =>
		{
			if  (!( 'action_challenge_challenge' in state.content))
			{
				return {};
			}
			else
			{
				const challenge = state.content.action_challenge_challenge.map((v) => ({ value: v.id.toString(), label: v.name }))
				return challenge;
			}
		},
		getContentActionCategory: state => (id:number): any | undefined  =>{
			if  (!( 'category_quiz' in state.content))
			{
				return {};
			}
			else
			{
				const category = translate(state.content.category_quiz.find(e => e.id == id), ["name"])
				return category;
			}
		},
		getContentBookingQuestion: state => (activities:number[]): any | undefined  =>{
			if  (!( 'bookingQuestion' in state.content))
			{
				return [];
			}
			else
			{
				if(activities.length > 0)
				{
					const returnItems:any = []
					state.content.bookingQuestion.forEach(e => {
						if(activities.includes(e.id))
						{
							returnItems.push(e)
						}
					});
					console.log(returnItems);
					
					return returnItems
				}
				else
				{
					return state.content.bookingQuestion
				}
			}
		},
		getUserProfile: state => (role:string): any | undefined  =>{
			if  (!( 'userProfile' in state.content))
			{
				return [];
			}
			else
			{
				if(role != null || role != undefined)
				{
					return state.content.userProfile.filter(e => e.rol == role)
				}
				else
				{
					return state.content.userProfile
				}
			}
		},
		getActivity: state => (id:number): any | undefined  =>{
			if  (!( 'activity' in state.content))
			{
				return {};
			}
			else
			{
				return state.content.activity.find(e => e.id == id);
			}
		},
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useContentStore, import.meta.hot))
}

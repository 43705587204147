import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import type { List } from "lodash";
import { defineStore, acceptHMRUpdate } from "pinia"
import { useTapasStore } from "./tapas";
import type { TapasChallenge } from "./interfaces/tapas_interfaces";
import { translate, translateAll } from "@/utils/translate";
import { useEventsStore } from "./events";
import { useContentStore } from "./content";
import { useRoute } from "vue-router";
import type { Notification } from "@/composables/useNotifications";
import dayjs from "@/utils/dayjs";

interface Booking_Item {
    id:Number,
    name:String,
    description:String,
	price?:String,
	activity:String,

}

export const useNotificationStore = defineStore("notification", {
	state: () => ({
		notifications:[],
		showDrawer: false,
	}),
	actions: {
		async fetchNotifications()
		{
			await axiosToken("get","notifications/get/").then((response)=>{
				this.notifications = response.data
				this.notifications.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at)})
			})
		},
		setNotification(id:number | string)
		{
			for (let i = 0; i < this.notifications.length; i++) {
				const e = this.notifications[i];
				if(parseInt(id) == e.id)
				{
					e.read = true
					break;
				}
			}
		},
		setAllNotifications()
		{
			for (let i = 0; i < this.notifications.length; i++) {
				const e = this.notifications[i];
				e.read = true
			}
		}
	},
	persist: {
		// paths: ["events"]
	},
	getters:{
		getNotifications: state => (): any | undefined => {
			if(state.notifications.length == 0)
			{
				return []
			}
			const result:Notification[] = []

			const notifications = JSON.parse(JSON.stringify(state.notifications));
			const notifications_sorted = notifications.sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at)})
			
			notifications_sorted.forEach(e => {
				const new_obj:Notification = {
					id: e.id,
					category: 'reminder',
					type: 'warning',
					title: e.data_html,
					description: '',
					read: e.read,
					date: dayjs(new Date(e.created_at)).format('DD/MM/YYYY HH:MM')
				}
				result.push(new_obj)
			})
			return result
		},

		getUnreadNotifications: state => (): any | undefined => {
			
			let result = false

			if(state.notifications.length == 0)
			{
				return result
			}
			
			for (let i = 0; i < state.notifications.length; i++) {
				const e = state.notifications[i];
				if(!e.read)
				{
					result = true
					break;
				}
			}
			return result;
		}
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
}
